
import { defineComponent } from "vue-demi";
import { COMMENTS } from "../constants";
import { COURSES_ENUM } from "@/utils/enums";

export default defineComponent({
  name: "ProfileComments",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comments: COMMENTS,
    };
  },
  computed: {
    currentComments() {
      this.setComments();
      return this.comments;
    },
  },
  methods: {
    setComments() {
      if (this.user.course.type === COURSES_ENUM.ONE) {
        this.comments[2].sections = [
          { id: "student", name: "Комментарий эксперта на портфель студента" },
        ];
      } else {
        this.comments[2].sections = [
          { id: "stock", name: "Обзор рисковой части" },
          { id: "bond", name: "Обзор консервативной части" },
          { id: "alternative", name: "Обзор защитной части" },
          { id: "tactic", name: "Тактические идеи" },
        ];
      }
    },
    async saveComments() {
      const result = await this.API.common.onSaveComments({
        comments: this.user.comments,
      });
      this.$store.commit("updateUser", result.data);
    },
  },
});
